.pftfr {
    background-image: url('/assets/img/plays/prayer/background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 2rem 0;
    position: relative;
    min-height: 800px;
    /* Add a low z-index to the entire prayer component to ensure it stays below the dropdown menus */
    z-index: 0;
    
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-image: url('/assets/img/plays/prayer/paint.png');
        background-repeat: no-repeat;
        background-position: top right;
        background-size: 75% auto;
    }

    > .cell {
        position: relative;
    }

    h2 {
        color: #fff;  // Make h2 text white
    }

    .show-title {
        text-align: center;
        margin-bottom: 2rem; 
        padding-left: 2rem;
        
        img {
            max-width: 100%;
            height: auto;
        }
    }

    .show-hero {
        margin-bottom: 2rem;
        
        img {
            width: 100%;
            height: auto;
        }
    }

    .show-description {
        margin-bottom: 2rem;
        background-color: rgba(255, 255, 255, 0.7);
        padding: 2rem;
        border-radius: 4px;

        .lead {
            font-size: 1.25rem;
            font-weight: 500;
            margin-bottom: 1.5rem;
        }
    }

    .show-details {
        background-color: rgba(255, 255, 255, 0.7);
        padding: 2rem;
        margin-top: 2rem;
        border-radius: 4px;

        h3 {
            margin-bottom: 1rem;
        }

        .dates {
            font-size: 1.2rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
        }

        .runtime {
            font-style: italic;
        }

        .button {
            margin-top: 1rem;
        }
    }
}
