.citytalk {
  .city_logo {
    background-image: url("/assets/img/citytalk/skyline.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
    height: 200px;
    position: relative;
    display: block;
    z-index: 100;
    margin-bottom: -2rem;
  }
  
  /* Speaker image hover effect */
.speaker-container {
    position: relative;
    display: block;
    margin-bottom: 1rem;
  }
  

.speaker-container img {
    display: block;
    width: 100%;
  }
  
.speaker-container:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: yellow;
    opacity: 0.3;
    pointer-events: none;
  }

.off-canvas p,
.off-canvas h3,
.off-canvas h4 {
    margin-left: 15px;
    margin-right: 15px;
  }

.session-title {
    font-size: 1.4em;
    }
.session-credit {
    font-size: 1em;
    line-height: 1.2;
    }
.session-date {
    font-weight: bold;
    font-size: 1.2em;
    }
}
