@font-face {
    font-family: 'sketchnote_text_web';
    src: url('/assets/webfonts/sketchnote_text_bold_web.eot');
    src: url('/assets/webfonts/sketchnote_text_bold_web.eot?#iefix') format('embedded-opentype'),
         url('/assets/webfonts/sketchnote_text_bold_web.woff') format('woff'),
         url('/assets/webfonts/sketchnote_text_bold_web.ttf') format('truetype'),
         url('/assets/webfonts/sketchnote_text_bold_web.svg#sketchnote_text_webbold') format('svg');
    font-weight: bold;
    font-style: normal;

}

@font-face {
    font-family: 'sketchnote_text_web';
    src: url('/assets/webfonts/sketchnote_text_italic_web.eot');
    src: url('/assets/webfonts/sketchnote_text_italic_web.eot?#iefix') format('embedded-opentype'),
         url('/assets/webfonts/sketchnote_text_italic_web.woff') format('woff'),
         url('/assets/webfonts/sketchnote_text_italic_web.ttf') format('truetype'),
         url('/assets/webfonts/sketchnote_text_italic_web.svg#sketchnote_text_webitalic') format('svg');
    font-weight: normal;
    font-style: italic;

}

@font-face {
    font-family: 'sketchnote_text_web';
    src: url('/assets/webfonts/sketchnote_text_reg_web.eot');
    src: url('/assets/webfonts/sketchnote_text_reg_web.eot?#iefix') format('embedded-opentype'),
         url('/assets/webfonts/sketchnote_text_reg_web.woff') format('woff'),
         url('/assets/webfonts/sketchnote_text_reg_web.ttf') format('truetype'),
         url('/assets/webfonts/sketchnote_text_reg_web.svg#sketchnote_text_webregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'sketchnote_dingbats_webRg';
    src: url('/assets/webfonts/sketchnote_dingbats_web.eot');
    src: url('/assets/webfonts/sketchnote_dingbats_web.eot?#iefix') format('embedded-opentype'),
         url('/assets/webfonts/sketchnote_dingbats_web.woff') format('woff'),
         url('/assets/webfonts/sketchnote_dingbats_web.ttf') format('truetype'),
         url('/assets/webfonts/sketchnote_dingbats_web.svg#sketchnote_dingbats_webRg') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'sketchnote_square_webregular';
    src: url('/assets/webfonts/sketchnote_square_web.eot');
    src: url('/assets/webfonts/sketchnote_square_web.eot?#iefix') format('embedded-opentype'),
         url('/assets/webfonts/sketchnote_square_web.woff') format('woff'),
         url('/assets/webfonts/sketchnote_square_web.ttf') format('truetype'),
         url('/assets/webfonts/sketchnote_square_web.svg#sketchnote_square_webregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
