@import "app-fonts";

$bg_image: url(/assets/img/textures/chalkboard-gray-6.png);

body {
  background-image: $bg_image;
  background-repeat: repeat;
  background-color: none;
}


body:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    background: -webkit-radial-gradient(top center, ellipse cover, rgba(255,255,255,0.2) 0%,rgba(0,0,0,0.5) 100%);
}


// Adjust width accordingly (use even #'s)
$logo-width: 118px;
// Reduce px value to increase space between logo and menu text
$logo-padding: $logo-width - 32px; 
// Leave alone
$logo-margin: - ($logo-width / 2);

/* Small Navigation */
.logo-small {
  float: right;
}

.title-bar {
  padding: 0 .5rem;
}

.menu-icon,
.title-bar-title {
  position: relative;
  top: 10px;
}

/* Medium-Up Navigation */
@media only screen and (min-width: 40rem) { 

  .logo-wrapper {
    position: relative;
    
    .logo {
      width: $logo-width;
      height:  92px;
      position: absolute;
      left: 50%;
      right: 50%;
      top: -6px;
      margin-left: $logo-margin;
    }
  }

  // Right part
  .top-bar-right {
    width: 50%;
    padding-left: $logo-padding;
    
    ul {
      float: left;
    }
  }
  
  // Left part
  .top-bar-left {
    width:  50%;
    padding-right: $logo-padding;
    
    ul {
      float: right;
    }
  }
}

.topbar-center-logo .top-bar-center {
  flex: 1 0 auto;

  @include breakpoint(small only) {
    display: none;
  }
}

.topbar-center-logo-mobile {

  .menu-icon {
    vertical-align: text-top;
  }
}


img#menu-logo {
  z-index: 500;
  position: relative;
}

/* Typographic control */

p.drawn-bar {
    font-family: "sketchnote_dingbats_webRg";
    font-size: 7em;
    line-height: 12px;
    text-align:center;
}


.paper {
  background-image: url("/assets/img/textures/natural_paper.png");
  padding: 15px 15px 15px 15px;
}

.blacktop {
  div {
    background-color: #000000;
    padding: 15px 15px 15px 15px;
    color: #FFFFFF;
  }
  .callout {
    background-color: white;
    color: black;
  }

}

.contrast {
  color: #FFF;
  a {
    color: #FF0;
  }
  blockquote,cite,p,h1,h2,h3,h4,h5,h6 {
    color: #FFF;
  }
}

.card .title { font-size: .8em; font-style: italic; }

.card .notes { font-size: .8em; }

img.centered {
    display: block;
    margin-left: auto;
    margin-right: auto
}

p.centered,h1.centered,h2.centered,h3.centered,h4.centered,h5.centered,h6.centered {
    text-align: center;
}

.pricing-table.primary .title {
    background-color: #008CBA;
    color: #FFFFFF
}

.padded-bottom-right {
  padding-bottom: 10px;
  padding-right: 10px;
}

.spaced-at-top {
  margin-top: 18px;
}


form  .vertical-center {
  margin-top: .6em;
}

fieldset legend {
  background: none;
}

form .hint {
  float:right;
  margin-top: -12px;
  font-size: 90%;
  color: #4d4d4d;
  padding-bottom: 5px;
}


.company_class {
  font-size: 80%;
  font-style: italic;
}

.venue {
  font-size: 80%;
  font-style: italic;
  font-weight: bolder;
}

.presenter {
  font-size: 80%;
  font-weight: bolder;
}
blockquote {
  font-size: 1.5em;
  margin: 0em 0 0em;
  padding: 0em .25em 0 2.5em;
  position:relative;
  

}

blockquote:before
 {
  //color: #ccc;
  content: "\201C";
  font-size: 2em;
  position:absolute;
  left:.5em;
  top: .5em;
  line-height: 0.1em;
  border-left: none !important;
}

blockquote:after
 {
  //color: #ccc;
  content: "\201D";
  font-size: 1em;
  position:absolute;
  right:3px;
  bottom: .5em;
  line-height: 0.1em;
}

blockquote cite {
    color: #999999;
    font-size: .8em;
    display: block;
    margin-top: 5px;
    text-align: right;
    padding-right: 1.5em;
}

blockquote cite:before {
    content: "\2014 \2009";
}

blockquote.small {
    font-size: 1.2em;
    cite {
      font-size: .8em;
    }
  }

.affiliation {
  font-size: .9em;
  font-style: italic;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3rem;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 3rem;    /* Footer height */
}

@import 'components/building-blocks/pricing-table';

.toc li {
  display: flex;
}

.toc li .left {
  order: 1;
}

.toc li .right {
  order: 3;
}

.toc li::after {
  background-image: radial-gradient(circle, currentcolor 1px, transparent 1.5px);
  background-position: bottom;
  background-size: 1ex 4.5px;
  background-repeat: space no-repeat;
  content: "";
  flex-grow: 1;
  height: 1em;
  order: 2;
}

blockquote.smallquote  {
    font-size: 100%;
}

.white-links {
  a:link,a:visited {
    color: white;
  }
}
